<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">代理商管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">代理商业绩统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">

            <div title="代理商" class="searchboxItem ci-full">
              <span class="itemLabel">代理商:</span>
              <el-input
                v-model="agencyName"
                type="text"
                size="small"
                placeholder="请输入代理商"
                clearable
              />
            </div>
            <div title="开班结束时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem">开班结束时间:</span>
              <el-date-picker
                v-model="theClassTime"
                size="small"
                type="daterange"
                style="width: 5rem"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div class="btnBox" style="margin-left: 20px">
              <el-button
                style="margin-left: 20px"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button class="bgc-bv" round @click="handleExport()"
                >导出</el-button
              >
            </div>
          </div>
          <!-- <div class="searchbox" style="padding-top: 10px">
            <div
              title="代理商"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">代理商:</span>
              <el-select
                v-model="agencyId"
                placeholder="请选择"
                no-data-text="没有数据"
                remote
                size="small"
                clearable
                :remote-method="superAgencySelect"
                @visible-change="clearAgencySearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      "
                      >联系人姓名</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      "
                      >联系人电话</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 30px;
                        width: 160px;
                      "
                      >企业名称</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="UserName"
                      v-on:input="superAgencySelect"
                      type="text"
                      size="small"
                      placeholder="联系人姓名"
                      clearable
                      style="width: 120px"
                    />
                    <el-input
                      v-model="Userphone"
                      v-on:input="superAgencySelect"
                      type="text"
                      size="small"
                      placeholder="联系人电话"
                      clearable
                      style="margin-left: 30px; width: 140px"
                    />
                    <el-input
                      v-model="compName"
                      v-on:input="superAgencySelect"
                      type="text"
                      size="small"
                      placeholder="企业名称"
                      clearable
                      style="margin-left: 30px; width: 160px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="item in agencyList"
                  :key="item.agencyId"
                  :label="item.name"
                  :value="item.agencyId"
                >
                  <span
                    style="
                      width: 100px;
                      font-size: 13px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
                    >{{ item.name }}</span
                  >
                  <span
                    style="
                      width: 140px;
                      margin-left: 50px;
                      font-size: 13px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
                    >{{ item.photo }}</span
                  >
                  <span
                    style="
                      width: 160px;
                      margin-left: 50px;
                      font-size: 13px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
                    >{{ item.compName }}</span
                  >
                </el-option>
              </el-select>
            </div>
          </div> -->
        
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="协议名称"
                align="left"
                prop="contractName"
                show-overflow-tooltip
                min-width="200"
                fixed
              />
              
              <el-table-column
                label="行政区划"
                align="left"
                prop="contentNamePath"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="代理商"
                align="left"
                prop="companyName"
                show-overflow-tooltip
                min-width="260"
              />
              <el-table-column
                label="代理商联系人"
                align="left"
                prop="agencyName"
                show-overflow-tooltip
                min-width="260"
              />
            
              <el-table-column
                label="联系人"
                align="left"
                prop="partyAName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="签订时间"
                align="left"
                prop="signDate"
                show-overflow-tooltip
                min-width="100"
              >
              <template slot-scope="scope">
                  {{scope.row.signDate | momentDate}}
              </template>
              </el-table-column>
              <el-table-column
                label="合同结束时间"
                align="left"
                prop="endDate"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.endDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="付费标准"
                align="left"
                prop="chargesSettlement"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="拓展情况"
                align="left"
                prop="compNum"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="开班数量"
                align="left"
                prop="projectNum"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="培训人数"
                align="left"
                prop="peopleNum"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="结业人数"
                align="left"
                prop="graduationNum"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="合同金额"
                align="left"
                prop="contractAmount"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="到账金额"
                align="left"
                prop="arrivalAmount"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="应付金额"
                align="left"
                prop="payableAgencyFee"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="备注"
                align="left"
                prop="remark"
                show-overflow-tooltip
                min-width="200"
              />
              <el-table-column
                label="操作"
                align="center"
                width="140px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleDetail(scope.row)"
                    >详情</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/accountList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      agencyName: "",
      theClassTime: "",
    };
  },
  computed: {},
  created() {},
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.agencyName) {
        params.agencyName = this.agencyName;
      }
      if (this.theClassTime) {
        params.startDate = this.theClassTime[0];
        params.endDate = this.theClassTime[1];
      }
      this.doFetch({
        url: "/agency/ma-agency-organ-static/pageList",
        params,
        pageNum,
      });
    },
    handleExport() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          const params = {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
          };
          if (this.agencyName) {
        params.agencyName = this.agencyName;
      }
      if (this.theClassTime) {
        params.startDate = this.theClassTime[0];
        params.endDate = this.theClassTime[1];
      }
          this.$post("/agency/ma-agency-organ-static/exportPageList", params).then((res) => {
            if (res.status == "0") {
              window.open(res.message);
            }
          });
        })
        .catch(() => {});
    },
    handleDetail(row) {
       this.$router.push({
        path: "/web/agentInstitution",
        query: { agencyId:row.agencyId},
      });
    },
    //列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 ) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}
</style>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
